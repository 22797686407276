@font-face {
  font-family: 'Syne';
  src: url('../components/fonts/SyneMadison-Bold.eot');
  src: url('../components/fonts/SyneMadison-Bold.woff') format('woff'),
    url('../components/fonts/SyneMadison-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Syne';
  src: url('../components/fonts/SyneMadison-Extra.eot');
  src: url('../components/fonts/SyneMadison-Extra.woff') format('woff'),
    url('../components/fonts/SyneMadison-Extra.otf') format('opentype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-weight: inherit;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #000;
  font-size: 14px;
  line-height: 1.1;
  max-width: 100%;
  min-width: 320px;
  width: 100%;
  font-weight: 300;
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  background-color: #fff;
  font-family: 'Ubuntu', sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* user-select: none; */
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom, 150px)
    env(safe-area-inset-left);
}

#root {
  position: relative;
  z-index: 1;
}

a {
  color: #000000;
  cursor: pointer;
  text-decoration: none;
}

b {
  font-weight: 600;
}

li {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
}

form {
  position: relative;
}

input,
textarea {
  border: none;
  font-size: 15px;
  padding: 10px;
  border-radius: 0;
  outline: none;
}

input:focus,
textarea:focus {
  outline: none;
}

a:active {
  color: currentColor;
}

button,
select {
  text-transform: none;
}

button {
  -webkit-appearance: button;
  vertical-align: middle;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
button,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

input[type='text']:focus {
  outline: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
  -webkit-text-fill-color: 3b3f43;
  box-shadow: 0 0 0 30px #fff inset;
}

.tac {
  text-align: center;
}

.df {
  display: flex;
}

.dif {
  display: inline-flex;
}

.db {
  display: block;
}

.dib {
  display: inline-block;
  vertical-align: top;
}

.f-wrap {
  flex-wrap: wrap;
}

.jcsb {
  justify-content: space-between;
}

.jcc {
  justify-content: center;
}

.jcfe {
  justify-content: flex-end;
}

.aic {
  align-items: center;
}

.bold {
  font-weight: bold;
}

.semi-bold {
  font-weight: 500;
}

.gap1 {
  gap: 8px;
}

.gap2 {
  gap: 16px;
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.pointer {
  cursor: pointer;
}

.red {
  border: 1px solid red;
}

.mt1 {
  margin-top: 8px;
}

.mt2 {
  margin-top: 16px;
}

.mt3 {
  margin-top: 24px;
}

.mt4 {
  margin-top: 32px;
}

.mt5 {
  margin-top: 40px;
}

.mt6 {
  margin-top: 48px;
}

.mt7 {
  margin-top: 56px;
}

.mt8 {
  margin-top: 64px;
}

.mt9 {
  margin-top: 72px;
}

.mt10 {
  margin-top: 80px;
}

.ml1 {
  margin-left: 8px;
}

.ml2 {
  margin-left: 16px;
}

.ml3 {
  margin-left: 24px;
}

.ml4 {
  margin-left: 32px;
}

.ml5 {
  margin-left: 40px;
}

.ml6 {
  margin-left: 48px;
}

.ml7 {
  margin-left: 56px;
}

.ml8 {
  margin-left: 64px;
}

.ml9 {
  margin-left: 72px;
}

.ml10 {
  margin-left: 80px;
}

.mr1 {
  margin-right: 8px;
}

.mr2 {
  margin-right: 16px;
}

.mr3 {
  margin-right: 24px;
}

.mr4 {
  margin-right: 32px;
}

.mr5 {
  margin-right: 40px;
}

.mr6 {
  margin-right: 48px;
}

.mr7 {
  margin-right: 56px;
}

.mr8 {
  margin-right: 64px;
}

.mr9 {
  margin-right: 72px;
}

.mr10 {
  margin-right: 80px;
}

.mb1 {
  margin-bottom: 8px;
}

.mb2 {
  margin-bottom: 16px;
}

.mb3 {
  margin-bottom: 24px;
}

.mb4 {
  margin-bottom: 32px;
}

.mb5 {
  margin-bottom: 40px;
}

.mb6 {
  margin-bottom: 48px;
}

.p1 {
  padding: 8px;
}

.p2 {
  padding: 16px;
}

.p3 {
  padding: 24px;
}

.p4 {
  padding: 32px;
}

.p5 {
  padding: 40px;
}

.p6 {
  padding: 48px;
}

.pl1 {
  padding-left: 8px;
}

.pl2 {
  padding-left: 16px;
}

.pl3 {
  padding-left: 24px;
}

.pl4 {
  padding-left: 32px;
}

.pl5 {
  padding-left: 40px;
}

.pl6 {
  padding-left: 48px;
}

.pr1 {
  padding-right: 8px;
}

.pr2 {
  padding-right: 16px;
}

.pr3 {
  padding-right: 24px;
}

.pr4 {
  padding-right: 32px;
}

.pr5 {
  padding-right: 40px;
}

.pr6 {
  padding-right: 48px;
}

.px1 {
  padding-left: 8px;
  padding-right: 8px;
}

.px2 {
  padding-left: 16px;
  padding-right: 16px;
}

.px3 {
  padding-left: 24px;
  padding-right: 24px;
}

.px4 {
  padding-left: 32px;
  padding-right: 32px;
}

.px5 {
  padding-left: 40px;
  padding-right: 40px;
}

.px6 {
  padding-left: 48px;
  padding-right: 48px;
}

.py1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py5 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py6 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.mat {
  margin-left: auto;
  margin-right: auto;
}

.underline {
  text-decoration: underline;
}

.allcaps {
  text-transform: uppercase;
}

.cap {
  text-transform: capitalize;
}

.radius {
  border-radius: 4px;
}

.circle {
  border-radius: 50%;
}

.bg-white {
  background-color: #fff;
}

.shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.relative {
  position: relative;
}

.index-150 {
  z-index: 150;
}

.tablet-only,
.landscape-only {
  display: none;
}

.container {
  padding: 0 20px;
  max-width: 1440px;
  position: relative;
  width: 100%;
}

.clear:after {
  content: '';
  display: table;
  clear: both;
}

/* .green {
  color: #7cc99e;
} */

.invisible {
  opacity: 0;
}

.test {
  border: 1px solid red;
}

.parallax-outer {
  /* height: 245px; */
  position: absolute;
  width: 100%;
}

.page-header__title {
  font-family: Syne, sans-serif;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.38;
  color: #000;
}

.section-header__title {
  font-family: Syne, sans-serif;
  font-size: 24px;
  font-weight: 300;
}

.header-styles {
  font-size: 24px;
  font-family: 'Syne';
}

@media (min-width: 768px) {
  .page-header__title {
    font-size: 40px;
    line-height: 1.38;
  }

  .container {
    margin: 0px auto;
    padding-left: 32px;
    padding-right: 32px;
  }

  .md-flex {
    display: flex;
  }

  .md-row {
    flex-direction: row;
  }

  .mobile-view {
    display: none;
  }

  .tablet-only {
    display: block;
  }

  /* .parallax-outer {
    height: 395px;
  } */
}

.lenders-slider-cards {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.sans {
  font-family: sans-serif;
}

.syne {
  font-family: Syne, sans-serif;
  font-weight: 300;
}

.ubuntu {
  font-family: 'Ubuntu', sans-serif;
}

.fs-12 {
  font-size: 12px;
}

@media (min-width: 1024px) {
  .landscape-only {
    display: block;
  }

  .page-header__title {
    font-size: 48px;
    line-height: 0.98;
  }

  .section-header__title {
    font-size: 32px;
  }

  .header-styles {
    font-size: 32px;
  }
}

@media (min-width: 1260px) {
  .landscape-only {
    display: block;
  }
}

@media (min-width: 1440px) {
  .header-styles {
    font-size: 48px;
  }

  .page-header__title {
    font-size: 64px;
    line-height: 1.25;
  }

  .section-header__title {
    font-size: 48px;
  }
}

/* Slider */

.slick-slide > div {
  padding: 0 10px;
}

.slick-arrow.slick-arrow::before {
  color: #0827c7;
  display: none;
}

.slick-arrow.slick-arrow {
  z-index: 1000;
}

.slick-track.slick-track {
  display: flex;
  align-items: center;
}

.slick-dots.slick-dots {
  display: flex !important;
  justify-content: center;
  height: 24px;
}

.slick-dots.slick-dots li {
  width: 9px;
  height: 4px;
  margin: 0 4px;
  transition: all 0.3s;
}

.slick-dots.slick-dots li button {
  width: 9px;
  height: 4px;
  transition: all 0.3s;
}

.slick-dots.slick-dots li.slick-active {
  width: 24px;
  transition: all 0.3s;
}

.slick-dots.slick-dots li button:before {
  height: 4px;
  transition: all 0.3s;
  width: 9px;
  border-radius: 4px;
  /* margin-right: 8px; */
  background-color: #bbc0d4;
  opacity: 1;
  font-size: 0;
}

.slick-dots.slick-dots li button:hover:before {
  background-color: #0827c7;
}

.slick-dots li.slick-active button:before {
  width: 24px;
  background-color: #0827c7;
  opacity: 1;
}

/* class="ReactModal__Overlay ReactModal__Overlay--after-open" */
.ReactModalPortal {
  position: relative;
  z-index: 500;
}

/* .ReactModal__Content--after-open {
  border-radius: 0px !important;
} */

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

@media (min-width: 768px) {
  .slick-slide > div {
    padding: 0 30px;
  }

  .slick-dots.slick-dots {
    bottom: -50px;
  }
}

@media (min-width: 1260px) {
  .slick-slide > div {
    padding: 0 30px;
  }

  .slick-dots.slick-dots {
    bottom: -20px;
  }
}

@media (max-width: 1919px) {
  .slick-prev,
  .slick-next {
    display: none !important;
  }
}

.ReactCollapse--collapse {
  transition: height 200ms;
}

.slick-initialized.slick-initialized {
  width: 100%;
}
