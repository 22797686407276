.rootS {
  min-height: calc(100vh - 250px);
  padding: 24px;
  text-align: center;
  position: relative;
  z-index: 5px;
}

.message {
  color: #000e56;
  display: block;
  font-style: normal;
  margin-bottom: 16px;
  margin-top: 16px;
}

.illustWrap {
  margin-top: 24;
}

.illustWrap svg {
  width: 100%;
  height: auto;
  max-width: 75%;
}

.textwrap h1 {
  color: #6ceac0;
}

.motifOne {
  position: absolute;
  right: 50px;
  top: -50px;
  width: 100%;
  z-index: 0;
  display: none;
}

@media (min-width: 768px) {
  .illustWrap {
    margin-top: 40px;
  }
  .illustwrap svg {
    max-width: 60%;
  }
  .textwrap {
    margin-top: 56px;
  }
  .motifOne {
    display: block;
    top: -40px;
    max-width: 250px;
    right: 32px;
  }
}

@media (min-width: 1024px) {
  .rootS {
    padding: 32px;
    padding-top: 64px;
    max-width: 90%;
    margin: auto;
  }
  .illustWrap {
    display: inline-block;
    width: 49%;
    vertical-align: top;
    margin-top: 24px;
  }
  .illustwrap svg {
    max-width: 500px;
  }
  .textwrap {
    display: inline-block;
    width: 49%;
    padding-left: 24px;
    text-align: left;
  }
  .textwrap p {
    max-width: 375px;
    line-height: 1.4;
  }
}

@media (min-width: 1260px) {
  .rootS {
    max-width: 1260px;
  }
  .textwrap p {
    max-width: 505px;
  }
}

@media (min-width: 1360px) {
  .motifOne {
    max-width: 398px;
    right: 56px;
    top: -60px;
  }
}
